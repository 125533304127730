define("@market/ember/modifiers/did-market-load", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /*
   * Calls the provided function when the element is hydrated.
  
   * Usage:
   * {{!-- example.hbs --}}
   * <market-table {{did-market-load this.onMarketLoad}}>
   *  {{#if this.tableLoaded}}
   *   <VerticalCollection>
   *    {{!-- table content --}}
   *   </VerticalCollection>
   *  {{/if}}
   * </market-table>
   *
   * {{!-- example.js --}}
   * @tracked tableLoaded = false;
   * @action onMarketLoad() {
   *  this.tableLoaded = true;
   * }
   */
  var _default = (0, _emberModifier.modifier)(function didMarketLoad(element, _ref) {
    var _ref2 = _slicedToArray(_ref, 1),
      onMarketLoad = _ref2[0];
    /*
     * The ability to properly type modifiers was added in ember-modifier@4.0.0.
     * (https://github.com/ember-modifier/ember-modifier/pull/210)
     *
     * This is a workaround to prevent the compiler from complaining about types.
     * This logic should be updated when the ember-modifier package is updated.
     */
    (false && !('componentOnReady' in element) && Ember.assert('did-market-load can only be installed on market components', 'componentOnReady' in element));
    (false && !(typeof onMarketLoad === 'function') && Ember.assert('parameter must be a function', typeof onMarketLoad === 'function'));
    var elementAsStencil = element;
    var onMarketLoadAsFunction = onMarketLoad;
    elementAsStencil.componentOnReady().then(function (el) {
      onMarketLoadAsFunction(el);
    });
  });
  _exports.default = _default;
});