define("ember-intl/-private/error-types", ["exports", "intl-messageformat"], function (_exports, _intlMessageformat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MISSING_TRANSLATION = _exports.MISSING_INTL_API = void 0;
  /**
   * An error that indicates a required Intl API was not available
   * at runtime. This is generally resolved by polyfilling the missing API.
   *
   * @private
   * @hide
   */
  // @ts-ignore If the consuming project uses `--isolatedModules`, `const enums`
  // may not be used. Since `ember-cli-babel` does not care for `const enums`
  // _anyway_ , this is not an issue.
  var MISSING_INTL_API = _intlMessageformat.ErrorCode.MISSING_INTL_API;

  /**
   * An error type that indicates a translation that was looked up
   * by a specific key was not found.
   *
   * @private
   * @hide
   */
  _exports.MISSING_INTL_API = MISSING_INTL_API;
  var MISSING_TRANSLATION = 'MISSING_TRANSLATION';
  _exports.MISSING_TRANSLATION = MISSING_TRANSLATION;
});