define("ember-cp-validations/-private/result", ["exports", "ember-cp-validations/validations/result-collection", "ember-cp-validations/validations/warning-result-collection", "ember-cp-validations/-private/internal-result-object"], function (_exports, _resultCollection, _warningResultCollection, _internalResultObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var readOnly = Ember.computed.readOnly;

  /**
   * __PRIVATE__
   *
   * @module Validations
   * @class Result
   * @private
   */

  var Result = Ember.Object.extend({
    /**
     * @property model
     * @type {Object}
     */
    model: null,
    /**
     * @property attribute
     * @type {String}
     */
    attribute: '',
    /**
     * @property _promise
     * @async
     * @private
     * @type {Promise}
     */
    _promise: null,
    /**
     * The validator that returned this result
     * @property _validator
     * @private
     * @type {Validator}
     */
    _validator: null,
    /**
     * Determines if the _result object is readOnly.
     *
     * This is needed because ResultCollections and global validation objects control their own
     * state via CPs
     *
     * @property _isReadOnly
     * @private
     * @readOnly
     * @type {Boolean}
     */
    _isReadOnly: Ember.computed('_result', function () {
      var validations = Ember.get(this, '_result');
      return validations instanceof _resultCollection.default || Ember.get(validations, 'isValidations');
    }).readOnly(),
    /**
     * @property isWarning
     * @readOnly
     * @type {Boolean}
     */
    isWarning: readOnly('_validator.isWarning'),
    /**
     * @property isValid
     * @readOnly
     * @type {Boolean}
     */
    isValid: readOnly('_result.isValid'),
    /**
     * @property isInvalid
     * @readOnly
     * @type {Boolean}
     */
    isInvalid: readOnly('_result.isInvalid'),
    /**
     * @property isValidating
     * @readOnly
     * @type {Boolean}
     */
    isValidating: readOnly('_result.isValidating'),
    /**
     * @property isTruelyValid
     * @readOnly
     * @type {Boolean}
     */
    isTruelyValid: readOnly('_result.isTruelyValid'),
    /**
     * @property isTruelyInvalid
     * @readOnly
     * @type {Boolean}
     */
    isTruelyInvalid: readOnly('_result.isTruelyInvalid'),
    /**
     * @property isAsync
     * @readOnly
     * @type {Boolean}
     */
    isAsync: readOnly('_result.isAsync'),
    /**
     * @property message
     * @readOnly
     * @type {String}
     */
    message: readOnly('_result.message'),
    /**
     * @property messages
     * @readOnly
     * @type {Array}
     */
    messages: readOnly('_result.messages'),
    /**
     * @property error
     * @readOnly
     * @type {Object}
     */
    error: readOnly('_result.error'),
    /**
     * @property errors
     * @readOnly
     * @type {Array}
     */
    errors: readOnly('_result.errors'),
    /**
     * @property warningMessage
     * @readOnly
     * @type {String}
     */
    warningMessage: readOnly('_result.warningMessage'),
    /**
     * @property warningMessages
     * @readOnly
     * @type {Array}
     */
    warningMessages: readOnly('_result.warningMessages'),
    /**
     * @property warning
     * @readOnly
     * @type {Object}
     */
    warning: readOnly('_result.warning'),
    /**
     * @property warnings
     * @readOnly
     * @type {Array}
     */
    warnings: readOnly('_result.warnings'),
    /**
     * This hold all the logic for the above CPs. We do this so we can easily switch this object out with a different validations object
     * @property _result
     * @private
     * @type {Result}
     */
    _result: Ember.computed('model', 'attribute', '_promise', '_validator', '_resultOverride', function () {
      return Ember.get(this, '_resultOverride') || _internalResultObject.default.create(Ember.getProperties(this, ['model', 'attribute', '_promise', '_validator']));
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (Ember.get(this, 'isAsync') && !Ember.get(this, '_isReadOnly')) {
        this._handlePromise();
      }
    },
    /**
     * Update the current validation result object with the given value
     * - If value is undefined or null, set isValid to false
     * - If value is a validations object from a different model/object, set the _result object to the one given (belongs-to)
     * - If value is a collection of result objects, create a Validation Result Collection and set that to the _result object (has-many)
     * - If value is a string, set the message to the given string and set isValid to false
     * - If value is a boolean, set isValid to result
     * - If value is a pojo, update _result object with the information given
     *
     * @method update
     * @private
     * @param value
     */
    update: function update(value) {
      var result = Ember.get(this, '_result');
      var attribute = Ember.get(this, 'attribute');
      var isWarning = Ember.get(this, 'isWarning');
      var Collection = isWarning ? _warningResultCollection.default : _resultCollection.default;
      if (Ember.isNone(value)) {
        return this.update(false);
      } else if (Ember.get(value, 'isValidations')) {
        this._overrideResult(Collection.create({
          attribute: attribute,
          content: [value]
        }));
      } else if (Ember.isArray(value)) {
        this._overrideResult(Collection.create({
          attribute: attribute,
          content: value
        }));
      } else if (!Ember.get(this, '_isReadOnly')) {
        this._overrideResult(undefined);
        if (typeof value === 'string') {
          var _Ember$setProperties;
          Ember.setProperties(Ember.get(this, '_result'), (_Ember$setProperties = {}, _defineProperty(_Ember$setProperties, isWarning ? 'warningMessage' : 'message', value), _defineProperty(_Ember$setProperties, "isValid", isWarning ? true : false), _Ember$setProperties));
        } else if (typeof value === 'boolean') {
          Ember.set(result, 'isValid', value);
        } else if (_typeof(value) === 'object') {
          Ember.setProperties(result, value);
        }
      }
    },
    /**
     * Override the internal _result property.
     * @method _overrideResult
     * @param result
     * @private
     */
    _overrideResult: function _overrideResult(result) {
      Ember.set(this, '_resultOverride', result);
    },
    /**
     * Promise handler
     * @method _handlePromise
     * @private
     */
    _handlePromise: function _handlePromise() {
      var _this = this;
      Ember.get(this, '_promise').then(function (value) {
        return _this.update(value);
      }, function (value) {
        return _this.update(value);
      }).catch(function (reason) {
        // TODO: send into error state
        throw reason;
      });
    }
  });
  var _default = Result;
  _exports.default = _default;
});