define("ember-cp-validations/utils/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.callable = callable;
  _exports.compact = void 0;
  _exports.flatten = flatten;
  _exports.uniq = void 0;
  var A = Ember.A();
  function callable(method) {
    return function (collection) {
      return A[method].apply(collection, arguments);
    };
  }
  var uniq = callable('uniq');
  _exports.uniq = uniq;
  var compact = callable('compact');
  _exports.compact = compact;
  function flatten() {
    var array = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var result = [];
    for (var i = 0, l = array.length; i < l; i++) {
      var item = array[i];
      if (Array.isArray(item)) {
        result = result.concat(flatten(item));
      } else {
        result.push(item);
      }
    }
    return result;
  }
});