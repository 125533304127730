define("ember-cp-validations/utils/lookup-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lookupValidator;
  /**
   * Lookup a validator of a specific type on the owner
   *
   * @param  {Ember.Owner} owner
   * @param  {String} type
   * @throws {Error} Validator not found
   * @return {Class} Validator class
   */
  function lookupValidator(owner, type) {
    if (!owner) {
      throw new Error("[ember-cp-validations] `lookupValidator` requires owner/container access.");
    }
    var validatorClass = owner.factoryFor("validator:".concat(type));
    if (!validatorClass) {
      throw new Error("[ember-cp-validations] Validator not found of type: ".concat(type, "."));
    }
    return validatorClass;
  }
});