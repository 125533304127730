define("ember-cp-validations/validators/length", ["exports", "ember-cp-validations/-private/ember-validator"], function (_exports, _emberValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Validates the length of the attributes’ values.
   *
   *  ## Examples
   *
   *  ```javascript
   *  validator('length', {
   *    is: 15
   *  })
   *  validator('length', {
   *    min: 5,
   *    max: 10
   *  })
   *  ```
   *
   *  @class Length
   *  @module Validators
   *  @extends Base
   */
  var _default = _emberValidator.default.extend({
    _evType: 'length'
  });
  _exports.default = _default;
});