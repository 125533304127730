define("ember-pikaday/components/pikaday-input", ["exports", "ember-pikaday/mixins/pikaday"], function (_exports, _pikaday) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_pikaday.default, {
    tagName: 'input',
    attributeBindings: ['readonly', 'tabindex', 'disabled', 'placeholder', 'type', 'name', 'size', 'required', 'title', 'hidden', 'autocomplete'],
    type: 'text',
    autocomplete: 'off',
    didInsertElement: function didInsertElement() {
      this.set('field', this.element);
      this.setupPikaday();
    },
    onPikadayOpen: function onPikadayOpen() {
      this.get('onOpen')();
    },
    onPikadayClose: function onPikadayClose() {
      if (this.get('pikaday').getDate() === null || Ember.isEmpty(this.get('element').value)) {
        this.set('value', null);
        this.get('onSelection')(null);
      }
      this.get('onClose')();
    }
  });
  _exports.default = _default;
});