define("ember-cp-validations/-private/symbols", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALIDATIONS_CLASS = _exports.IS_VALIDATIONS_CLASS = _exports.ATTRS_RESULT_COLLECTION = _exports.ATTRS_PATH = _exports.ATTRS_MODEL = void 0;
  var VALIDATIONS_CLASS = '__VALIDATIONS_CLASS__';
  _exports.VALIDATIONS_CLASS = VALIDATIONS_CLASS;
  var IS_VALIDATIONS_CLASS = '__IS_VALIDATIONS_CLASS__';
  _exports.IS_VALIDATIONS_CLASS = IS_VALIDATIONS_CLASS;
  var ATTRS_MODEL = '__ATTRS_MODEL__';
  _exports.ATTRS_MODEL = ATTRS_MODEL;
  var ATTRS_PATH = '__ATTRS_PATH__';
  _exports.ATTRS_PATH = ATTRS_PATH;
  var ATTRS_RESULT_COLLECTION = '__ATTRS_RESULT_COLLECTION__';
  _exports.ATTRS_RESULT_COLLECTION = ATTRS_RESULT_COLLECTION;
});