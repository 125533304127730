define("@ember/polyfills/lib/assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assign = assign;
  _exports.default = void 0;
  /**
   @module @ember/polyfills
  */

  /**
    Copy properties from a source object to a target object. Source arguments remain unchanged.
  
    ```javascript
    import { assign } from '@ember/polyfills';
  
    var a = { first: 'Yehuda' };
    var b = { last: 'Katz' };
    var c = { company: 'Other Company' };
    var d = { company: 'Tilde Inc.' };
    assign(a, b, c, d); // a === { first: 'Yehuda', last: 'Katz', company: 'Tilde Inc.' };
    ```
  
    @method assign
    @for @ember/polyfills
    @param {Object} target The object to assign into
    @param {Object} ...args The objects to copy properties from
    @return {Object}
    @public
    @static
  */
  function assign(target) {
    for (var _i6 = 1; _i6 < arguments.length; _i6++) {
      var _arg3 = arguments[_i6];
      if (!_arg3) {
        continue;
      }
      var _updates3 = Object.keys(_arg3);
      for (var _i8 = 0; _i8 < _updates3.length; _i8++) {
        var _prop3 = _updates3[_i8];
        target[_prop3] = _arg3[_prop3];
      }
    }
    return target;
  } // Note: We use the bracket notation so
  //       that the babel plugin does not
  //       transform it.
  // https://www.npmjs.com/package/babel-plugin-transform-object-assign

  var _assign = Object.assign;
  var _default = _assign || assign;
  _exports.default = _default;
});